@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

/* html{background-color: rgb(248, 0, 0);} */

.App {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

body {
  min-height: 100vh;
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.posts {
  text-align: left;
  margin-top: 20px;
}

.post {
  border-bottom: 1px solid #ccc;
  padding: 10px 20px 20px 20px;
}

.post:nth-child(odd) {
  background-color: #f2f2f2;
}

.btn-download {
  text-align: center;
  margin-top: 20px;
}

button {
  background-color: #ccc;
  color: #555;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 1px;
  border: none;
  cursor: pointer;
  font-weight:bolder;
}

button:hover {
  background-color: #000;
  color: #fff;
}

input,
textarea {
  width: 100%;
  max-width: 100%;
  padding: 5px 5px 5px 0px;
  margin: 4px;
}

textarea {
  height: 100px;
}

h3 {
  text-transform: capitalize;
}

p {
  color: #666;
}

.logo {
  padding-left: 70px;
}

.placement {
  text-align: left;
}

.addUserPlacement {
  text-align: left;
}

nav {
  position: sticky;
  top: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.fNav{
  padding: 110px;
}

.NavlinkCustom {
  padding-right: 60px;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  /* width: 100vw; */
  height: auto;
}

.form-group{
 text-align: left;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

/* Style for the table container */

.table-container {
  margin: auto;
  scroll-margin-left: 4px;
  scroll-margin-right: 4px;
  padding: 4px;
  display: flex;
  flex-direction:column;
  align-self: auto;
}

/* Style for the table header row */
.header-row {
  background-color: #f2f2f217;
  font-weight: bold;
  font-size: large;
  text-align: center;
  justify-items: center;
}

/* Style for the table body rows */
.body-row {

  background-color: #fff;
}

/* Style for the table cell */
.cell {
  padding: 1px;
  border: 1px solid #ccc;
}

.filter-input {
  width:1.5cm;
  padding: 0px;
  margin: 4px 0;
  border: 1px solid #cccccc4e;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
  font-size:medium;
}
.main-content{
	width: 50%;
	border-radius: 20px;
	box-shadow: 0 5px 5px rgba(0,0,0,.4);
	margin: 5em auto;
	display: flex;
}
.company__info{
	background-color: #008080;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
}
.fa-android{
	font-size:3em;
}
@media screen and (max-width: 640px) {
	.main-content{width: 90%;}
	.company__info{
		display: none;
	}
	.login_form{
		border-top-left-radius:20px;
		border-bottom-left-radius:20px;
	}
}
@media screen and (min-width: 642px) and (max-width:800px){
	.main-content{width: 70%;}
}
.row > h2{
	color:#000080;
  margin-top: 10px;
}
.login_form{
	background-color: #fff;
	border-top-right-radius:20px;
	border-bottom-right-radius:20px;
	border-top:1px solid #ccc;
	border-right:1px solid #ccc;
}

.form__input{
	width: 100%;
	border:0px solid transparent;
	border-radius: 0;
	border-bottom: 1px solid #aaa;
	padding: 1em .5em .5em;
	padding-left: 2em;
	outline:none;
	margin:1.5em auto;
	transition: all .5s ease;
}
.form__input:focus{
	border-bottom-color: #000080;
	box-shadow: 0 0 5px rgba(#000080); 
	border-radius: 4px;
}
.btnn{
	/* transition: all .5s ease; */
	width: 70%;
	border-radius: 30px;
	color:#000080;
	font-weight: 600;
	background-color: #fff;
	border: 1px solid #000080;
	margin-top: 100px;
	margin-bottom: 1em;
  
}
.btnn:hover, .btnn:focus{
	background-color: #000080;
	color:#fff;
}

a:hover{
  cursor: pointer;
}

/* b:hover{
  cursor: pointer;
} */

b{
  margin-top: 30px;
	border-radius: 30px;
	background-color: #fff;
	margin: 0px;
}

h7:hover{
  cursor: pointer;
}

option {
  width: 200px;
  height: 100px;
  overflow-wrap: break-word;
}
